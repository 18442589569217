import { Image } from '@react-three/drei'
import { useEffect, useRef, useState, Suspense } from 'react'
import { CONTAINER_CLASS_NAME } from 'components/PortfolioItems/PortfolioScrollImages/PortfolioScrollImages'
import { BRAND_TITLE_CLASS } from 'components/PortfolioItems/PortfolioScrollText/PortfolioScrollText.js'
import useStore from 'store'
import { useFrame } from '@react-three/fiber'
import { lerp } from 'utils'
import wait from '@jam3/wait'
import gsap from 'gsap'
import useNewKeyOnWindowResize from 'utils/hooks/use-new-key-on-window-resize'
import { CURSOR_TYPES } from 'components/Cursor/Cursor'
import {
  PORTFOLIO_TRANSITION_DURATION,
  PORTFOLIO_SINGLE_TRANSITION_OUT_DURATION,
  PORTFOLIO_TRANSITION_DURATION_MS,
  PAGE_TRANSITION_DURATION_MS,
} from 'data'
import {
  MAIN_IMAGE_ID,
  SINGLE_LIST_CLASS_NAME,
} from 'components/PortfolioItems/PortfolioScrollImagesSingle/PortfolioScrollImagesSingle.js'

import { singleGalleryGap } from 'styles/export-vars.module.scss'
import useTransitionState from 'utils/hooks/use-transition-state'
import WebGLVideo from './Video'
import { hasValidPassword } from 'components/BlockedContentModal/BlockedContentModal'

const SINGLE_GALLERY_GAP = parseInt(singleGalleryGap)
const LERP_LEVEL = 0.1

function getAbsoluteHeight(el) {
  // Get the DOM Node if you pass in a string
  el = typeof el === 'string' ? document.querySelector(el) : el

  var styles = window.getComputedStyle(el)
  var margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom'])

  return Math.ceil(el.offsetHeight + margin)
}

export const IMAGE_GALLERY_TYPES = {
  MAIN: 'MAIN',
  SINGLE: 'SINGLE',
}

const STARTING_Y_MAIN = -1000
// const DEFAULT_VIDEO_TEXTURE = 'https://nathandallaire.b-cdn.net/default-video-optimized.mp4'

function Element({
  element,
  index,
  itemsLength,
  type = IMAGE_GALLERY_TYPES.MAIN,
  visible,
  isMain,
  assetType,
  isPasswordProtected,
}) {
  const ref = useRef()
  const [scaleValues, setScaleValues] = useState({ width: 0, height: 0, scalingFactor: 0 })
  const [transitionIsVisible, setTransitionIsVisible] = useState(false)
  const transitionIsVisibleTimeout = useRef(null)
  const offsets = useRef({ left: 0, top: 0 })
  const [imageSrc, setImageSrc] = useState(null)
  const subRef = useRef()
  const animatedInMain = useRef(false)
  const distanceScrolledTarget = useRef(0)
  const distanceScrolledCurrent = useRef(0)
  const elementTextHeight = useRef(0)
  const containerDimensions = useRef({ width: 0, height: 0 })
  const elementDimensions = useRef({ width: 0, height: 0 })
  const animatedRenderValues = useRef({ yOffset: STARTING_Y_MAIN, opacity: 0 })
  const portfolioWebglImageLoaded = useStore(state => state.portfolioWebglImageLoaded)
  const setActivePortfolioItemIndex = useStore(state => state.setActivePortfolioItemIndex)
  const setPortfolioState = useStore(state => state.setPortfolioState)
  const setPasswordPromptOpen = useStore(state => state.setPasswordPromptOpen)
  const setPasswordPromptGoToIndex = useStore(state => state.setPasswordPromptGoToIndex)
  const activePortfolioItemIndex = useStore(state => state.activePortfolioItemIndex)
  const portfolioState = useStore(state => state.portfolioState)
  const setCursorType = useStore(state => state.setCursorType)
  const key = useNewKeyOnWindowResize()
  const bottomMargin = useRef(0)
  const leftMargin = useRef(0)
  const singleParentCenterOffset = useRef()
  const rafValues = useRef({})
  const { isPortfolio } = useTransitionState()

  let evenOddOffset
  if (type === IMAGE_GALLERY_TYPES.MAIN) {
    evenOddOffset = itemsLength % 2 === 0 ? scaleValues.height / 2 : 0
  }
  if (type === IMAGE_GALLERY_TYPES.SINGLE) {
    evenOddOffset = itemsLength % 2 === 0 ? scaleValues.width / 2 : 0
  }

  /* ================================
  Set Original Sizing
  ================================ */
  const setSizing = async () => {
    if (!element) return

    if (!visible) {
      animatedRenderValues.current.opacity = 0
    }

    if (element.dataset.src) {
      setImageSrc(element.dataset.src)
    }

    let containerElement
    if (type === IMAGE_GALLERY_TYPES.MAIN) {
      containerElement = document.querySelectorAll(`.${CONTAINER_CLASS_NAME}`)[0]
    } else if (type === IMAGE_GALLERY_TYPES.SINGLE) {
      containerElement = element.closest(`.${SINGLE_LIST_CLASS_NAME}`)
    }

    elementDimensions.current = {
      width: element.offsetWidth,
      height: getAbsoluteHeight(element),
    }

    if (type === IMAGE_GALLERY_TYPES.MAIN) {
      if (!containerElement) return

      const heightScale = 0.95
      const containerElementOffsetTop = containerElement?.getBoundingClientRect()?.top
      const startingPosition =
        Math.abs(containerElementOffsetTop) + (window.innerHeight * 0.5 - element.offsetHeight * 0.5)
      const elementHeight = element.offsetHeight
      const width = element.offsetWidth
      const height = element.offsetHeight * heightScale
      const scale = width / window.innerWidth

      setScaleValues({
        width,
        height,
        scalingFactor: scale,
      })

      bottomMargin.current = elementHeight - height
      containerDimensions.current.height = elementHeight * itemsLength + bottomMargin.current
      let fromTop = startingPosition - element.offsetTop

      const fromLeft = element.getBoundingClientRect().left
      offsets.current = { left: fromLeft, top: fromTop }

      /* ================================= */
      // RAF Calculated Values
      /* ================================= */

      rafValues.current.offset = containerDimensions.current.height * 0.5 + elementDimensions.current.height * 0.5

      if (index === 0) {
        rafValues.current.offset = rafValues.current.offset - bottomMargin.current * 0.25
      }

      rafValues.current.startingPos = containerDimensions.current.height - element.offsetTop

      rafValues.current.getCenterNormalized = delta => {
        const centerNormalized = Math.max(
          1 -
            Math.abs(
              Math.abs(delta - scaleValues.height * 0.5 - containerDimensions.current.height * 0.5) /
                elementDimensions.current.height,
            ),
          0,
        )

        return centerNormalized
      }
    }

    if (type === IMAGE_GALLERY_TYPES.SINGLE) {
      if (!containerElement) return
      const constainerElementOffsetLeft = containerElement?.getBoundingClientRect()?.left
      const startingPosition =
        Math.abs(constainerElementOffsetLeft) + (window.innerWidth * 0.5 - element.offsetWidth * 0.5)

      const width = element.offsetWidth
      const height = element.offsetHeight
      let scale = width / window.innerWidth

      setScaleValues({
        width,
        height,
        scalingFactor: scale,
      })

      leftMargin.current = SINGLE_GALLERY_GAP

      singleParentCenterOffset.current = parseInt(containerElement.dataset.offset)

      containerDimensions.current.width = containerElement.offsetWidth + SINGLE_GALLERY_GAP
      const fromTop = startingPosition - element.offsetWidth
      const fromLeft = element.offsetLeft
      offsets.current = { left: fromLeft, top: fromTop }

      if (ref.current) {
        ref.current.position.z = 5
      }
    }
  }

  useFrame(() => {
    if (!visible) return

    if (ref.current) {
      // Learp
      distanceScrolledCurrent.current = lerp(
        distanceScrolledCurrent.current,
        distanceScrolledTarget.current,
        LERP_LEVEL,
      )

      ref.current.__r3f.objects[1].uniforms.opacity.value = animatedRenderValues.current.opacity

      /* ============================
      MAIN Image Gallery Types
      ============================ */
      if (type === IMAGE_GALLERY_TYPES.MAIN) {
        let delta =
          (-distanceScrolledCurrent.current + rafValues.current.startingPos + containerDimensions.current.height) %
          containerDimensions.current.height

        if (delta < 0) {
          delta = containerDimensions.current.height + delta
        }

        const centerNormalized = rafValues.current.getCenterNormalized(delta)
        const grayScaleValue = Math.max(1 - centerNormalized, 0)

        const posY = delta - rafValues.current.offset - bottomMargin.current

        if (portfolioState !== 'TRANSITIONING_TO_SINGLE') {
          ref.current.position.y = posY + animatedRenderValues.current.yOffset + evenOddOffset
          ref.current.__r3f.objects[1].uniforms.grayscale.value = grayScaleValue
        }
      }

      /* ============================
      SINGLE Image Gallery Types
      ============================ */
      if (type === IMAGE_GALLERY_TYPES.SINGLE) {
        if (assetType === 'image') {
          ref.current.material.uniforms.scale.value = [
            elementDimensions.current.width,
            elementDimensions.current.height,
          ]
        }

        // const offset = containerDimensions.current.width * 0.5 - elementDimensions.current.width * 0.5
        const scroll = -distanceScrolledCurrent.current
        const offset = singleParentCenterOffset.current + elementDimensions.current.width * 0.5
        // const startingPos = containerDimensions.current.width - element.offsetLeft - SINGLE_GALLERY_GAP
        // const startingPos = containerDimensions.current.width - (element.offsetLeft + SINGLE_GALLERY_GAP)
        const startingPos = element.offsetLeft
        let delta = (scroll + startingPos + containerDimensions.current.width) % containerDimensions.current.width

        if (delta < 0) {
          delta = containerDimensions.current.width + delta
        }

        let posX = delta + offset

        const y =
          window.innerHeight * 0.5 - elementDimensions.current.height * 0.5 - element.getBoundingClientRect().top

        if (portfolioState !== 'TRANSITIONING_TO_MAIN') {
          // ref.current.position.x = posX + elementDimensions.current.width * 0.5 + leftMargin.current * 0.5
          ref.current.position.x = posX
          ref.current.position.y = y
        }
      }
    }
  })

  /* ================================
  Animate In MAIN
  ================================ */
  useEffect(() => {
    if (
      !portfolioWebglImageLoaded ||
      type !== IMAGE_GALLERY_TYPES.MAIN ||
      (portfolioState !== 'MAIN' && portfolioState !== 'TRANSITIONING_TO_MAIN') ||
      !isPortfolio ||
      animatedInMain.current
    ) {
      return
    }

    animatedInMain.current = true

    animatedRenderValues.current = {
      opacity: 1,
      yOffset: containerDimensions.current.height,
    }

    gsap.to(animatedRenderValues.current, {
      yOffset: 0,
      duration: 1.2,
      ease: 'Power3.easeOut',
      delay: PORTFOLIO_TRANSITION_DURATION * 0.5,
    })
  }, [portfolioWebglImageLoaded, index, type, portfolioState, isPortfolio])

  /* ================================
  Animate out MAIN, go to SINGLE
  ================================ */
  useEffect(() => {
    if (activePortfolioItemIndex === null || type !== IMAGE_GALLERY_TYPES.MAIN) return

    const isActive = activePortfolioItemIndex === index

    if (!ref?.current) return

    gsap.killTweensOf(animatedRenderValues.current)

    gsap.to(animatedRenderValues.current, {
      opacity: isActive ? 1 : 0,
      duration: PORTFOLIO_TRANSITION_DURATION / 2,
      onComplete: () => {
        if (!isActive) {
          animatedRenderValues.current.yOffset = STARTING_Y_MAIN
        }
      },
    })

    if (!isActive) return

    const imageToMapTo = document.getElementById(MAIN_IMAGE_ID)

    if (!imageToMapTo) return

    const imageSize = {
      width: imageToMapTo.offsetWidth,
      height: imageToMapTo.offsetHeight,
      left: imageToMapTo.getBoundingClientRect().left,
      top: imageToMapTo.getBoundingClientRect().top,
    }

    const galleryLength = parseInt(imageToMapTo.dataset.galleryLength)
    const margin = imageSize.width - imageSize.width
    const evenOddOffsetMap = galleryLength % 2 === 0 ? 0 : imageSize.width + margin * 0.5
    let offsetLeft =
      imageSize.left - imageSize.width - window.innerWidth * 0.5 + imageSize.width * 0.5 + evenOddOffsetMap
    const diff = window.innerHeight * 0.5 - imageSize.height * 0.5
    const offsetTop = diff - imageSize.top

    const duration = PORTFOLIO_TRANSITION_DURATION
    const ease = 'Power4.easeInOut'

    const prevValues = {
      scale: {
        x: ref.current.scale.x,
        y: ref.current.scale.y,
      },
      position: {
        x: ref.current.position.x,
        y: ref.current.position.y,
      },
    }

    gsap.to(ref.current.scale, {
      x: imageSize.width,
      y: imageSize.height,
      onUpdate() {
        const x = this.targets()[0].x
        const y = this.targets()[0].y

        if (ref?.current?.material) {
          ref.current.material.uniforms.scale.value = [x, y]
        }
      },
      duration,
      ease,
    })

    gsap.to(ref.current.material.uniforms.grayscale, {
      value: 0,
      duration,
      ease,
    })

    gsap.to(ref.current.position, {
      // x: offsetLeft,
      y: offsetTop,
      duration,
      ease,
      onComplete: () => {
        setPortfolioState('SINGLE')

        // Reset to original values
        gsap.set(animatedRenderValues.current, {
          opacity: 0,
        })
        gsap.set(ref.current.scale, {
          x: prevValues.scale.x,
          y: prevValues.scale.y,
        })
        ref.current.material.uniforms.scale.value = [prevValues.scale.x, prevValues.scale.y]
        gsap.set(ref.current.position, {
          x: prevValues.position.x,
          y: prevValues.position.y,
        })
        animatedRenderValues.current.yOffset = STARTING_Y_MAIN
      },
    })
  }, [activePortfolioItemIndex, index, type])

  /* ================================
  Animate in/out SINGLE
  ================================ */
  useEffect(() => {
    if (type !== IMAGE_GALLERY_TYPES.SINGLE) return
    if (portfolioState === 'MAIN' || portfolioState === 'SINGLE') return

    const isIn = portfolioState === 'TRANSITIONING_TO_SINGLE'

    if (isIn) {
      distanceScrolledCurrent.current = distanceScrolledTarget.current
    }

    const delay = isIn ? (isMain ? PORTFOLIO_TRANSITION_DURATION : PORTFOLIO_TRANSITION_DURATION * 0.9) : 0
    const duration = isIn ? (isMain ? 0 : PORTFOLIO_TRANSITION_DURATION) : PORTFOLIO_SINGLE_TRANSITION_OUT_DURATION

    gsap.killTweensOf(animatedRenderValues.current)

    if (isIn) {
      animatedRenderValues.current.opacity = 0
    }

    gsap.to(animatedRenderValues.current, {
      opacity: isIn ? 1 : 0,
      delay,
      duration,
      onComplete: () => {
        if (!isIn) {
          setActivePortfolioItemIndex(null)
        }
      },
    })

    if (!isIn) {
      setTimeout(() => {
        setPortfolioState('MAIN')
      }, PORTFOLIO_TRANSITION_DURATION_MS)
    }
  }, [portfolioState, type, isMain])

  /* ================================
  Subscribe to events/data
  ================================ */
  useEffect(() => {
    if (!element) return

    if (subRef.current) {
      subRef.current()
    }

    if (!visible) return

    const scrollingTextElement = document.querySelectorAll(`.${BRAND_TITLE_CLASS}`)[0]
    elementTextHeight.current = scrollingTextElement?.offsetHeight

    const updateScrollDistance = portfolioScrollDistance => {
      const ratio = elementDimensions.current.height / elementTextHeight.current

      // need to calc margin difference because otherwise scrolling leads to
      // delta between titles and slides
      let marginMultiplier

      if (type === IMAGE_GALLERY_TYPES.MAIN) {
        const containerHeightNoMargin = containerDimensions.current.height - bottomMargin.current
        marginMultiplier = containerDimensions.current.height / containerHeightNoMargin
      }

      if (type === IMAGE_GALLERY_TYPES.SINGLE) {
        const containerWidthNoMargin = containerDimensions.current.width - leftMargin.current
        marginMultiplier = containerDimensions.current.width / containerWidthNoMargin
      }

      distanceScrolledTarget.current = portfolioScrollDistance * ratio * marginMultiplier
    }

    // Reset MAIN images
    if (useStore.getState().portfolioScrollDistance !== 0 && type === 'MAIN') {
      updateScrollDistance(useStore.getState().portfolioScrollDistance)
    }

    // Reset SINGLE images
    if (type === 'SINGLE') {
      updateScrollDistance(0)
    }

    subRef.current = useStore.subscribe(
      state => state.portfolioScrollDistance,
      value => {
        // Distance
        const portfolioScrollDistance = value
        updateScrollDistance(portfolioScrollDistance)
      },
    )

    return () => {
      if (subRef.current) {
        subRef.current()
      }
    }
  }, [element, key, visible])

  /* ================================
  Set if it's visible between transitions
  ================================ */
  useEffect(() => {
    if (visible) return

    setCursorType(null)
    animatedRenderValues.current = {
      opacity: 0,
      yOffset: containerDimensions.current.height,
    }
    animatedInMain.current = false
  }, [visible])

  /* ================================
  Setup sizing
  ================================ */
  useEffect(() => {
    setSizing()
  }, [visible, element, key])

  // Handlers
  const handlePointerOver = () => {
    if (!isPortfolio) return
    if (type === IMAGE_GALLERY_TYPES.MAIN) {
      if (portfolioState !== 'MAIN') return
      setCursorType(CURSOR_TYPES.HOVER)
    }
  }

  const handlePointerOut = () => {
    if (!isPortfolio) return
    if (type === IMAGE_GALLERY_TYPES.MAIN) {
      setCursorType(null)
    }
  }

  const handleClick = () => {
    if (!isPortfolio) return
    if (type === IMAGE_GALLERY_TYPES.MAIN) {
      if (portfolioState !== 'MAIN') return

      // if (!index && index !== 0) return // Apparently this stops the weird wrong click thing????

      if (isPasswordProtected && !hasValidPassword()) {
        setPasswordPromptOpen(true)
        setPasswordPromptGoToIndex(index)
        return
      }

      setActivePortfolioItemIndex(index)
      setPortfolioState('TRANSITIONING_TO_SINGLE')
    }
  }

  if (!element || !imageSrc) return null

  const props = {
    visible: visible,
    onPointerOver: handlePointerOver,
    onPointerOut: handlePointerOut,
    onClick: handleClick,
    opacity: 0,
    transparent: true,
    scale: [scaleValues.width, scaleValues.height, 1],
    ref: ref,
    toneMapped: false,
  }

  if (assetType === 'video') {
    return (
      <Suspense>
        <WebGLVideo
          {...props}
          // texture={videoTexture}
          imageSrc={imageSrc}
          element={element}
          assetType={assetType}
          scaleValues={scaleValues}
        />
      </Suspense>
    )
  }

  if (assetType === 'image') {
    return (
      <Image
        {...props}
        url={imageSrc}
      />
    )
  }

  return null
}

export default Element
