import { useRouter } from 'next/router'

export default function useTransitionState() {
  const router = useRouter()
  const isPortfolio = router.pathname === '/' || router.asPath === '/' || router.pathname === ''
  const isAbout = router.asPath.includes('/about')

  return {
    isPortfolio,
    isAbout,
  }
}
