import React, { memo, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import Head from '../Head/Head'
import Header from '../Header/Header'
import IntroLoader from '../IntroLoader/IntroLoader'
import Cursor from '../Cursor/Cursor'
import WebGL from '../WebGL/WebGL'
import styles from './Layout.module.scss'
import useStore from '../../store'
import { PORTFOLIO_TRANSITION_DURATION_MS, PAGE_TRANSITION_DURATION_MS } from 'data'
import Sections from 'components/Sections/Sections'
import PortfolioScrollImages from 'components/PortfolioItems/PortfolioScrollImages/PortfolioScrollImages.js'
import PortfolioScrollImagesSingle from 'components/PortfolioItems/PortfolioScrollImagesSingle/PortfolioScrollImagesSingle.js'
import BlockedContentModal from 'components/BlockedContentModal/BlockedContentModal'

const Layout = React.forwardRef(({ pageData }, ref) => {
  const router = useRouter()
  const { asPath } = router
  const setCanInteract = useStore(state => state.setCanInteract)
  const canInteract = useStore(state => state.canInteract)
  const portfolioWebglImageLoaded = useStore(state => state.portfolioWebglImageLoaded)
  const loaderAnimationComplete = useStore(state => state.loaderAnimationComplete)
  const portfolioState = useStore(state => state.portfolioState)
  const setPrevPath = useStore(state => state.setPrevPath)
  const setTheme = useStore(state => state.setTheme)
  const prevPath = useStore(state => state.prevPath)
  const portfolioItems = useStore(state => state.portfolioItems)
  const setCurrentPageData = useStore(state => state.setCurrentPageData)
  const prevPathRef = useRef(null)

  const currentPageData = pageData?.allPages?.filter(
    page =>
      `/${page?.slug?.current}/` === router.pathname ||
      `/${page?.slug?.current}/` === router.asPath ||
      (router.pathname === '/' && page?.slug?.current === 'home'),
  )[0]

  useEffect(() => {
    setTimeout(() => {
      prevPathRef.current = router.asPath
    }, 20)
  }, [router.asPath])

  useEffect(() => {
    const handleRouteChange = url => {
      setPrevPath(prevPathRef.current)
    }
    router.events.off('routeChangeComplete', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, setPrevPath])

  useEffect(() => {
    setCurrentPageData(currentPageData)
  }, [currentPageData])

  useEffect(() => {
    document.body.classList[canInteract ? 'remove' : 'add']('disable-interactions')

    let theme = currentPageData?.theme
    if (!theme) {
      theme = 'dark'
    }

    document.body.dataset.theme = theme
    setTheme(theme)
  }, [canInteract, currentPageData, prevPath, setTheme])

  useEffect(() => {
    const disableInteraction =
      portfolioState === 'TRANSITIONING_TO_SINGLE' || portfolioState === 'TRANSITIONING_TO_MAIN'
    if (disableInteraction) {
      setCanInteract(false)

      setTimeout(() => {
        setCanInteract(true)
      }, PORTFOLIO_TRANSITION_DURATION_MS)
    }
  }, [setCanInteract, portfolioState])

  useEffect(() => {
    setCanInteract(false)

    setTimeout(() => {
      setCanInteract(true)
    }, PAGE_TRANSITION_DURATION_MS)
  }, [asPath])

  useEffect(() => {
    if (!portfolioWebglImageLoaded) return
    setTimeout(() => {
      setCanInteract(true)
    }, PORTFOLIO_TRANSITION_DURATION_MS)
  }, [portfolioWebglImageLoaded])

  return (
    <div
      className={styles.Layout}
      ref={ref}
    >
      <Head
        title={pageData?.metaData?.title}
        description={pageData?.metaData?.description}
        keywords={pageData?.metaData?.keywords}
        shareImageUrl={pageData?.metaData?.shareAsset?.asset?.url}
        robots={pageData?.metaData?.robots}
      />
      <Cursor />
      <Header />
      {!loaderAnimationComplete && <IntroLoader />}
      <WebGL />
      <Sections />
      {pageData?.allPages
        .filter(page => page?.modules?.length)
        .map((page, i) => (
          <Sections
            key={i}
            modules={page.modules}
          />
        ))}

      <PortfolioScrollImages />
      {portfolioItems.map((item, i) => (
        <PortfolioScrollImagesSingle
          key={i}
          index={i}
          images={item.images}
          mainImage={item.image}
          brand={item.brand}
        />
      ))}

      <BlockedContentModal />
    </div>
  )
})

export default memo(Layout)
